export const cmsContent = {
  config: {
    isPagesDisabled: false,
    defaultRedirectUrl: "https://ashjith.com/invite",
    isTimerEnabled: true,
    orderOfWorkShipEnabled: false,
    invitecolor: "#f9f5f2",
    isWebsiteShutdown: false,
  },
  pages: {
    home: {
      annoncementHeroText: "We are getting married",
      annoncementHeroDescription:
        "We have decided to get together and celebrate life. We're super excited to have you all with us on our big day and to be able to show you Kerala that we love so dearly.",
      homePageBadgeCta: "Navigate to Maps",
      bullets: [
        {
          shortDesc: "When is this ? ",
          longDesc:
            "We are in the final stages of coming up with a dreamy date.",
        },
        {
          shortDesc: "How can I attend ? ",
          longDesc: "A detailed invitation will be send out on our behalf.",
        },
      ],
      dateAnnocementFeed: [
        { shortText: "Date", longDesc: "Feb 3rd 2025", type: "calender" },
        {
          shortText: "Wedding",
          longDesc: "Thevalakkara, Kerala",
          type: "address",
          value: "https://maps.app.goo.gl/26G2eBs3Qf8kqjoD6",
        },
      ],
    },
    about: {
      nameCta: "Jithin Zacharia",
      jobCta: "Married guy",
      tentativeCta: "Developer",
      engagedCta: "Engaged guy",
      mail: "mailto:zaccydiai7@gmail.com",
      github: "https://github.com/Jithinqw",
      linkedIn: "https://linkedin.com/in/jithin-zacharia",
      madeWith: {
        mainCta: "Made with",
        techStack: [
          {
            url: "https://react.dev/favicon-32x32.png",
            alt: "React",
            techName: "React",
          },
          {
            url: "https://tailwindcss.com/favicons/apple-touch-icon.png?v=3",
            alt: "TailWind",
            techName: "Tailwind CSS",
          },
          {
            url: "https://www.cloudflare.com/favicon.ico",
            alt: "CloudFlare",
            techName: "Cloud Flare",
          },
          {
            url: "https://assets.vercel.com/image/upload/front/favicon/vercel/favicon.ico",
            alt: "Vercel",
            techName: "Vercel",
          },
          {
            url: "https://expressjs.com/images/favicon.png",
            alt: "Express",
            techName: "Express",
          },
          {
            url: "https://www.typescriptlang.org/favicon-32x32.png",
            alt: "TypeScript",
            techName: "Typescript",
          },
        ],
      },
      aboutOwner: [
        { displayName: "Github", link: "http://github.com/Jithinqw" },
        {
          displayName: "LinkedIn",
          link: "https://linkedin.com/in/jithin-zacharia",
        },
        { displayName: "Web", link: "https://jithinqw.github.io/" },
      ],
    },
    ourstory: {
      mainCta: "How it all started",
      mainDescription:
        "Gather around website visitors young, middle-aged, and old! Listen to my tale of finding love.",
      mainDescriptionLongText:
        "Let us rewind the clock to August 2023, a more turbulent time when COVID-19 was winding down. Jithin was figuring out how to move to a new country, while Ashley was busy with her lectures.",
      lowDeckDescriptionText:
        "Jithin and Ashley met online and began talking. They talked and talked until they fell in love. By this time, Jithin and Ashley had traveled thousands of miles to meet each other. Through it all, their commitment to one another, along with their shared love of food and movies, helped them navigate the bumps along the road to where they are now. They are now engaged to be married, and we are excited to invite you to celebrate our journey.",
      inviteHeading: "Thanks",
      inviteDescription:
        "We thank God, all the people, and the stars that aligned to bring us together. We invite you to the beautiful village of Thevelakkara, where we will be getting married.",
    },
    venue: {
      homePageBadgeCta: "Navigate to Maps",
      weddingVenueCta: "Venue",
      weddingVenueDescription:
        "Welcome to Thevelakkara, a name derived from Devalokakkara, meaning 'the land of the Gods.' Situated on the shores of Ashtamudi Lake, this beautiful Kerala village provides a picturesque backdrop for our wedding celebration.",
      hotelsCta: "Travelling....?!",
      hotelsDescription:
        "Travelling to our place...?!. We found few hotels you can stay and relax. Locations are selected based on proximity to venue or airports.",
      activitiesCta: "Explore around...",
      activitiesDescription:
        "Interested in exploring the area? We have curated a selection of interesting places near Kollam for you to enjoy during your visit.",
      venueHeads: [
        { displayValue: "Top", key: "top" },
        { displayValue: "Near Airport", key: "airport" },
        { displayValue: "Near Venue", key: "venue" },
      ],
      attractionHeads: [
        { displayValue: "Top", key: "top" },
        { displayValue: "Nature", key: "nature" },
        { displayValue: "Shopping", key: "shopping" },
      ],
      mainVenueFeed: [
        {
          title: "Courtyard by Marriott Kochi Airport",
          address: "https://maps.app.goo.gl/Jr963YMmMfBeJitQ8",
          id: "2234",
          rating: 4.4,
          image:
            "https://cf.bstatic.com/xdata/images/hotel/max1280x900/467181977.jpg?k=acf51c27795f7d1d00e5b72d03dd7ed59ff89f0cac57b3da50fbece662c8e529&o=&hp=1",
          starRating: 5,
          year: 1333,
          runtime: 212,
          genre: "asdasd",
          cast: "asdasd",
          head: "Airport",
          amenities: [
            "Pool",
            "Free Wifi",
            "Free Parking",
            "Air Conditioning",
            "Fitness center",
          ],
        },
        {
          title: "KTDC Grand Chaithram",
          address: "https://maps.app.goo.gl/oXZwNAAN1hYfNrC76",
          id: "24",
          rating: 4.1,
          image:
            "https://cf.bstatic.com/xdata/images/hotel/max1280x900/309913503.jpg?k=afc7f4b783695b1b624b25c5a962b9c9c734afb68220d499198b161f2ea272c5&o=&hp=1",
          starRating: 4,
          year: 1333,
          runtime: 212,
          genre: "asdasd",
          cast: "asdasd",
          head: "Top",
          amenities: [
            "Free Breakfast",
            "Free Parking",
            "Air Conditioning",
            "Wifi",
          ],
        },
        {
          title: "Presidency Airport Hotel",
          address: "https://maps.app.goo.gl/bd1hARTQSHf5FZHt9",
          id: "234",
          rating: 4.7,
          image:
            "https://cf.bstatic.com/xdata/images/hotel/max1280x900/509563728.jpg?k=3f121d55c97e2aec183c04fabbd44a8e6a673c0d0b7e4ab1bb9f43658bb2acef&o=&hp=1",
          starRating: 5,
          year: 1333,
          runtime: 212,
          genre: "asdasd",
          cast: "asdasd",
          head: "Airport",
          amenities: [
            "Pool",
            "Air Conditioning",
            "Free Wifi",
            "Air Conditioning",
          ],
        },
        {
          title: "Hotel Venkateswara",
          address: "https://maps.app.goo.gl/pnHVYyM7ibapcuAv6",
          id: "243211",
          rating: 4.1,
          image:
            "https://imgak.mmtcdn.com/pwa_v3/pwa_hotel_assets/bgdefault_bg.jpg",
          starRating: 3,
          head: "Top",
          amenities: [
            "Free Breakfast",
            "Free Wifi",
            "Air Conditioning",
            "Free Parking",
          ],
        },
        {
          title: "Hotel Grand E Muscat",
          address: "https://maps.app.goo.gl/88zgcz1HLNEtS33U8",
          id: "243211",
          rating: 3.1,
          image:
            "https://imgak.mmtcdn.com/pwa_v3/pwa_hotel_assets/bgdefault_bg.jpg",
          starRating: 3,
          head: "Venue",
          amenities: [
            "Free Breakfast",
            "Free Parking",
            "Free Wifi",
            "Air Conditioning",
          ],
        },
        {
          title: "Hotel Indraprastha",
          address: "https://maps.app.goo.gl/CQNFXZ2ihUFP1fwb8",
          id: "243211",
          rating: 3.7,
          image:
            "https://imgak.mmtcdn.com/pwa_v3/pwa_hotel_assets/bgdefault_bg.jpg",
          starRating: 3,
          head: "Venue",
          amenities: ["Restaurant", "Free Wifi", "Air Conditioning"],
        },
      ],
      mainAttractionFeed: [
        {
          title: "Munroe Island",
          address: "https://maps.app.goo.gl/G9xNaMPNsJRdF3vn8",
          id: "2234",
          rating: 4.4,
          image:
            "https://imgak.mmtcdn.com/pwa_v3/pwa_hotel_assets/bgdefault_bg.jpg",
          starRating: 5,
          head: "Nature",
          amenities: ["Boating", "Sightseeing", "Backwaters", "Fishing"],
        },
        {
          title: "Thangassery Light House",
          address: "https://maps.app.goo.gl/yyxE5s5kBMNN5vzM7",
          id: "24",
          rating: 3,
          image:
            "https://imgak.mmtcdn.com/pwa_v3/pwa_hotel_assets/bgdefault_bg.jpg",
          starRating: 4,
          amenities: ["Sea view"],
          head: "Top",
        },
        {
          title: "Palaruvi Waterfalls",
          address: "https://maps.app.goo.gl/5TxzQDx6o6Pbsu2TA",
          id: "24",
          rating: 3,
          image:
            "https://imgak.mmtcdn.com/pwa_v3/pwa_hotel_assets/bgdefault_bg.jpg",
          starRating: 4,
          amenities: ["Waterfalls"],
          head: "Nature",
        },
        {
          title: "RP Shopping Mall",
          address: "https://maps.app.goo.gl/q9QJYXamawSpAeVx7",
          id: "24",
          rating: 3,
          image:
            "https://imgak.mmtcdn.com/pwa_v3/pwa_hotel_assets/bgdefault_bg.jpg",
          starRating: 3,
          amenities: ["Shopping"],
          head: "Shopping",
        },
        {
          title: "Jatayu Earth's Center",
          address: "https://maps.app.goo.gl/f5fG2WYV85ff1E3k8",
          id: "24",
          rating: 3,
          image:
            "https://imgak.mmtcdn.com/pwa_v3/pwa_hotel_assets/bgdefault_bg.jpg",
          starRating: 5,
          amenities: ["Great View", "Outdoor", "Cable cars"],
          head: "Top",
        },
      ],
    },
    orderofworkship: {
      mainCta: "This Page will be available soon...",
      bodyContent:
        "On our special day, we will publish order of workship here.",
    },
    gallery: {
      buttonTextCTA: "Enjoy our memories",
      images: [
        { src: "bg.png", alt: "image1" },
        { src: "bg.png", alt: "image1" },
        { src: "bgimg.jpg", alt: "image1" },
        { src: "bg.png", alt: "image1" },
        { src: "bgimg.jpg", alt: "image1" },
        { src: "bg.png", alt: "image1" },
        { src: "bgimg.jpg", alt: "image1" },
        { src: "bgimg.jpg", alt: "image1" },
        { src: "bg.png", alt: "image1" },
      ],
    },
    invite: {
      InviteHeroSection: {
        heroImage: "bg-default.jpg",
        heroImageAlt: "Jithin & Ashley",
      },
      instagramFeed: {
        mainFeed: [
          {
            userName: "jithin_zacharia",
            reelId: "14",
            userImage: "http://localhost:3000/ashjithlogo.png",
            userSubText: "Suggested for you",
            postImage: "http://localhost:3000/bg.png",
            postDescription: "asdasdasdasdasd",
            appPostedDate: "14 July",
            likes: [
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
            ],
          },
          {
            userName: "jithin_zacharia",
            reelId: "14",
            userImage: "http://localhost:3000/ashjithlogo.png",
            userSubText: "Suggested for you",
            postImage: "http://localhost:3000/bg.png",
            postDescription: "asdasdasdasdasd",
            appPostedDate: "14 July",
            likes: [
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
            ],
          },
          {
            userName: "jithin_zacharia",
            reelId: "14",
            userImage: "http://localhost:3000/ashjithlogo.png",
            userSubText: "Suggested for you",
            postImage: "http://localhost:3000/bg.png",
            postDescription: "asdasdasdasdasd",
            appPostedDate: "14 July",
            likes: [
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
            ],
          },
          {
            userName: "jithin_zacharia",
            reelId: "14",
            userImage: "http://localhost:3000/ashjithlogo.png",
            userSubText: "Suggested for you",
            postImage: "http://localhost:3000/bg.png",
            postDescription: "asdasdasdasdasd",
            appPostedDate: "14 July",
            likes: [
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
              {
                userName: "asdasd",
                userImage:
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
              },
            ],
          },
        ],
        reelsFeed: [
          {
            reelId: "12",
            userReelName: "Ashley",
            userReelImageSrc: "http://localhost:3000/ashjithlogo.png",
            userReelImageAlt: "user 1",
          },
          {
            reelId: "14",
            userReelName: "Jithin",
            userReelImageSrc: "http://localhost:3000/ashjithlogo.png",
            userReelImageAlt: "user 1",
          },
        ],
      },
    },
    notFound: {
      mainHeading: "Hmmm....It seems you got lost.",
      subHeading:
        '<span>If you are stuck, try going back <a href="/" class="underline text-black">home</a></span>',
      goHomeCTA: "Go back home",
    },
    drm: {
      mainCta: "Terms and Conditions",
      lastUpdatedOn: "Updated On: Thu. 7th Nov, 2024",
      mainDec:
        "These terms and conditions outline the rules and regulations for the use of ashjith.com's Website, located at ashjith.com. By accessing this website we assume you accept these terms and conditions. Do not continue to use ashjith.com if you do not agree to take all of the terms and conditions stated on this page.",
      terms: [
        {
          heading: "Personal Information",
          desc: "This website does not store, send or process personal or personally identifiable data.",
        },
        {
          heading: "Reporting",
          desc: "We employ services to identify if the user expericece is getting degraded due to any network, data or other issues.",
        },
        {
          heading: "Cookies",
          desc: "This site store cookies for security and other reasons. By using this website you agree to this use.",
        },
        {
          heading: "Digital Media",
          desc: "This site displays digital media on various pages. We have obtained consent from individuals in these media. Downloading, recirculating or maniplating images are not allowed and by using this website you agree to this condition.",
        },
        {
          heading: "Usage",
          desc: "This site is only to be used by people who are invite to the view this website. Visiting, manipulating, reirculating this website links are not allowed. By using this website you agree that you are invited to use this website and manipulations and recirculations are not conducted.",
        },
        {
          heading: "Hyperlinking to this website",
          desc: "Users are not allowed to display hyperlinks that redirect to this website, unless you are provincial or fedral goverment agency reporting crime, abuse or related issues."
        },
        {
          heading: "Links",
          desc: "This site uses Google Maps redirect links, by clicking on these links you agree to terms, conditions and privacy policy of Google Inc.",
        },
        {
          heading: "iFrames",
          desc: "Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.",
        },
        {heading: "Source Code", desc: "Source code for this webiste is avaliable online, but the owner will not be accepting any PR, pull request or feature requests. After Feb 3rd, 2025 source code will be private or archived."},
        {
          heading: "Expire",
          desc: "After 3rd Feb 2025, owner is planning to bring down, degrade or shutdown this website. Services to any visitor after this date is not guaranteed.",
        },
      ],
    },
    blacksite: {
      mainCta: "Hmmmm.... Invalid Link.",
      bodyDescription:
        "It seems you are using a invalid or expired link. Please make sure you are a invited guest.",
      goHomeCta: "Go back Home",
      goHomeLink: "/",
      contactHostCta: "Write to host",
      contactHostLink: "mailto:zaccydiai7@gmail.com",
    },
  },
  common: {
    weddingDate: "Mon Feb 3 2025 10:00:00",
    complimentsMainText: "Wishes from family & friends",
  },
  invite: {
    headingTopCa: "",
  },
  heading: {
    headingPages: [
      { headingTitle: "Our Story", source: "/story" },
      { headingTitle: "Venue", source: "/venue" },
      { headingTitle: "Order Of Workship", source: "/order" },
      { headingTitle: "Gallery", source: "/gallery" },
    ],
  },
  modal: {
    errorModalHeading: "Illegal Invite link Found",
    errorModaldescription:
      "It appears that you are using an unknown invitation link. If you believe this link is correct, please contact the host for clarification. If you arrived here by mistake, we recommend returning to the homepage.",
    inviteModalHeading: "We’d love your esteemed presence",
    inviteModalhi: "Hi",
    inviteModalDescription:
      "We kindly request the honor of your presence at our wedding.",
    errorModalCancelCTA: "Cancel",
    errorModalGoHomeCTA: "Go Home",
    inviteModalReject: "Reject",
    inviteModalAccept: "Accept",
  },
  compliments: [
    {
      mainText: "ആശംസകൾ",
      wisherName: "Jithin",
      wisherRole: "",
      wisherRelation: "Me",
    },
  ],
  warning: {
    boldHeadingSr: "Warning!",
    noteHeading: "",
    mainHeading:
      "Please note that recommendations, star ratings and user reviews are subject to change.",
  },
  footer: {
    madeWith: "Crafted at the foothills of the Rocky Mountains 🇨🇦",
    drmCta: "Terms & Privacy Policy",
    drmLink: "/drm",
  },
};
